import { z } from 'zod';

import { guestKey } from '~/utils/players';

import { playerActionSchema } from './action.schema';
import { messageSchema } from './message';
import { languageCodeSchema } from './utils.schema';

export const contactKey = z.object({
  key: z.union([z.literal(guestKey), z.string()]),
});
export type ContactKey = z.infer<typeof contactKey>;

export const contactSchema = z.object({
  key: z.string(),
  name: z.string().nullable(),
  photoLink: z.string().nullable(),
  photoAlternative: z.string(),
  languageCode: languageCodeSchema,
  checkedIn: z.boolean(),
  // checkOutTimestamp: z
  //   .string()
  //   .transform((v) => new Date(v.replace(' ', 'T')))
  //   .nullable(),
  confirmed: z.boolean().nullish(),
  messages: z.array(messageSchema).nullish(),
  actions: z.union([playerActionSchema.transform((player) => [player]), z.array(playerActionSchema)]).optional(),
});

export const contactSchemaSimple = contactSchema.omit({
  languageCode: true,
  checkedIn: true,
  confirmed: true,
  // checkOutTimestamp: true,
  messages: true,
  actions: true,
});

export type Contact = z.infer<typeof contactSchema>;
export type ContactSimple = z.infer<typeof contactSchemaSimple>;

type NonPlayerProps = 'languageCode'; // 'checkOutTimestamp'
export type Player = Omit<Contact, NonPlayerProps>;

export const guestContactSchema = z
  .object({
    key: z.literal(guestKey).nullable(),
    name: z.null(),
    photoLink: z.null(),
    photoAlternative: z.literal('IN'),
    languageCode: languageCodeSchema,
    checkedIn: z.literal(false),
    // checkOutTimestamp: z.null(),
    confirmed: z.boolean().nullish(),
    actions: z.union([playerActionSchema.transform((player) => [player]), z.array(playerActionSchema)]),
  })
  .transform((contact) => ({ ...contact, key: guestKey }));

export type GuestContact = z.infer<typeof guestContactSchema>;
export type GuestPlayer = Omit<GuestContact, NonPlayerProps>;
