import { z } from 'zod';

import { cartLineActionSchema, itemActionSchema, openItemActionSchema } from './action.schema';
import { contactKey, contactSchema, guestContactSchema } from './contact.schema';
import { messageSchema } from './message';
import {
  addDatesTransformFn,
  addTypeNameFn,
  dateSchema,
  dateTimeViewSchema,
  dayOfWeekSchema,
  militaryTimeSchema,
  playerCountSchema,
} from './utils.schema';

// Shared item properties
export const itemType = z.enum(['location', 'reservation']);
export type ItemType = z.infer<typeof itemType>;
export const itemBaseSchema = z.object({
  actions: z.array(itemActionSchema),
  dateFrom: dateSchema,
  dateSchedule: dateSchema,
  dateTimeView: dateTimeViewSchema.nullable().default('date_time_duration'),
  dateTo: dateSchema,
  description: z.string(),
  duration: z.string(),
  group: z.string(),
  groupDescription: z.string().nullable(),
  icon: z.string().nullish(),
  isPaidItem: z.boolean().optional(),
  key: z.string(),
  playerCount: playerCountSchema,
  playerCountOptions: z.array(z.object({ playercount: z.number() })).nullish(),
  players: z.array(z.union([contactSchema, guestContactSchema])).nullish(),
  timeFrom: militaryTimeSchema,
  timeTo: militaryTimeSchema,
  type: itemType,
  typeName: z.string(),
});

/** Schema to validate body of a `PUT` or `POST` request to mutate a `cartLine`. */
export const cartLineSchema = z.object({
  itemType: itemType,
  itemKey: z.string(),
  dateSchedule: z.string(),
  players: z.array(contactKey),
  actionType: z.string(),
});
export type CartLine = z.infer<typeof cartLineSchema>;

// ---------------------------------------------------------------------------
// Unordered (as in: not yet booked / purchased)
// ---------------------------------------------------------------------------

export const filterGroupItemProps = z.object({
  dayOfWeek: dayOfWeekSchema,
  group: z.string(),
  locationGroupCode: z.string(),
  isPaidItem: z.boolean().optional(),
  itemPrice: z.number().nullable(),
  maxGuestCount: z.number().int().min(0),
  playerCount: playerCountSchema,
  sportCode: z.string(),
  startTimeBlock: z.string(),
  timePeriod: z.string(),
});

// Item (item can be ordered/booked by the current user)
export const itemSchema = itemBaseSchema
  .merge(filterGroupItemProps)
  .transform(addDatesTransformFn)
  .transform(addTypeNameFn('item'));
export type Item = z.infer<typeof itemSchema>;

// ---------------------------------------------------------------------------
// Ordered (as in: booked / purchased item)
// ---------------------------------------------------------------------------

export const openItemStatusCode = z.enum(['confirmed', 'unconfirmed']);
export const orderedItemBaseSchema = itemBaseSchema.extend({
  messages: z.array(messageSchema).nullish(),
  statusCode: openItemStatusCode.nullable(),
  statusName: z.string().nullable(),
});

// Open item (booked /purchased item, with a `dateTimeFrom` that "should be" in the future)
export const openItemType = z.enum(['reservation', 'course-register', 'product', 'invoice']);
export const openItemBaseSchema = orderedItemBaseSchema.extend({
  actions: z.array(openItemActionSchema),
  dayOfWeek: dayOfWeekSchema,
  message: z.string().nullish(),
  maxGuestCount: z.number().int().min(0).optional(),
  type: openItemType,
});

export const openItemSchema = openItemBaseSchema.transform(addDatesTransformFn).transform(addTypeNameFn('openItem'));
export type OpenItem = z.infer<typeof openItemSchema>;

// Cart line item (item added to the current user's cart, but not yet confirmed)
export const cartLineItemStatusCode = z.enum(['open']);
export const cartLineItemType = z.enum(['reservation']);

const cartLineItemBaseSchema = orderedItemBaseSchema.merge(
  z.object({
    actions: z.array(cartLineActionSchema),
    expiresAt: z.string().transform((v) => new Date(v.replace(' ', 'T'))),
    maxGuestCount: z.number().int().min(0),
    type: cartLineItemType,
  }),
);

export const cartLineItemSchema = z
  .union([
    cartLineItemBaseSchema,
    // These null-items are filtered out in the API response
    z.object({
      key: z.literal(null),
      dateFrom: z.literal(null),
      timeFrom: z.literal(null),
      dateTo: z.literal(null),
      timeTo: z.literal(null),
    }),
  ])
  .transform(addDatesTransformFn)
  .transform(addTypeNameFn('cartLineItem'));

export const cleanCartLineItemSchema = cartLineItemBaseSchema
  .transform(addDatesTransformFn)
  .transform(addTypeNameFn('cartLineItem'));

export type CleanCartLineItem = z.infer<typeof cleanCartLineItemSchema>;
